/* Variables */

$navbar-bg: var(--grey1);
$navbar-bg-hover: var(--grey2);
$navbar-bg-focus: transparent;
$navbar-color: var(--emphasis-lowest);
$navbar-color-hover: var(--primary);
$navbar-active-color: var(--emphasis-lowest);
$navbar-active-bg: transparent;
$navbar-active-shadow-small: unset;
$navbar-active-shadow-medium: unset;

/* Navigation */

.navbar {
  background: $navbar-bg;

  a,
  .button {
    &:focus {
      outline-color: var(--secondary);
    }
  }
}

/* Title bar */

.title-bar {
  position: relative;
  background-color: var(--grey1);

  @include breakpoint(small medium down) {
    border-top: unset;
  }
  .language-choose {
    border: 1px solid transparent;
    & > ul > li > a {
      padding: 8px 9px !important;
      margin-right: 6px;
      border-radius: 50px;
      text-align: center;
      &::after {
        display: none !important;
      }
      &:hover,
      &:focus {
        background: var(--primary-10);
        fill: var(--primary);
      }
    }

    .language-chooser-controltext {
      text-align: center;
      .icon {
        width: 16px;
        height: 16px;
        transform: translate(1px, 2px);
      }
      & > span[aria-hidden="true"] {
        display: none;
      }
    }
  }

  .skip {
    @include skip-button;

    &:focus {
      outline-color: var(--secondary);
    }
  }

  a,
  .button {
    &:focus {
      outline-color: var(--secondary);
    }
  }
}

.logo-cityhall {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  margin-top: -1.95rem;
  text-align: right;

  img {
    width: auto;
    height: 22px !important;
  }

  @include breakpoint(medium) {
    top: 50%;
    margin-top: -19px;

    img {
      height: 38px !important;
    }
  }
}
input#input-search {
  height: 100%;
}
.topbar__search {
  position: relative;
  max-width: 220px; // Safari fix

  input {
    color: var(--emphasis-med);
    border-color: transparent;
    box-shadow: none;
    background: var(--white);
    margin-top: rem-calc(10);
    display: inline-block;
    border-radius: 4px 0 0 4px;
    transition: none;
    height: rem-calc(40); //matches input group button height

    &:focus {
      color: var(--emphasis-high);
      box-shadow: none;
      background: var(--white);
      outline-color: var(--secondary);
    }

    @include breakpoint(medium) {
      background: var(--white);
    }
  }

  .input-group-button {
    position: relative;
    height: 1px; // Fix for Safari and Firefox
    vertical-align: top;
  }

  // Fix for Safari and Firefox
  .button-wrapper {
    position: relative;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    button {
      min-height: rem-calc(40);
      border-radius: 0 4px 4px 0;
    }
  }

  button {
    padding: 5px 10px;
    transition: none;
  }

  form:first-child {
    display: inline-flex;
  }

  .input-group {
    margin-bottom: 0;
    padding: 0;
    .input-group-field {
      padding: 5px 10px;
    }
  }

  &:not(:focus-within) {
    button {
      background-color: var(--white);
      color: var(--emphasis-med);

      @include breakpoint(medium) {
        background: var(--white);
      }
    }
  }

  &:focus-within {
    input {
      background-color: var(--white);
      color: var(--emphasis-med);
      border: 0;

      &::placeholder {
        color: var(--emphasis-lowest);
        font-size: 16px;
      }
    }
  }
}

// Hack to fix the top bar display for IE11
// This cannot be applied to other browsers as the focus borders would be
// invisible if overflow is hidden on the search element.
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .topbar__search {
    overflow: hidden;
  }
}

/* Top bar */
.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  &.row {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    max-width: 100%;
  }
  .topbar__edit__link {
    margin-left: 1rem;
    & > a {
      @extend .btn;
      @extend .btn--secondary;
      @extend .btn--small;
    }
  }
  .topbar__search {
    min-height: 39px;
    label,
    input {
      margin-bottom: 0;
      min-height: 39px;
    }
    input {
      color: var(--emphasis-med);
      letter-spacing: normal;
      @extend .body-4;
      &::placeholder,
      &:focus-within::placeholder {
        @extend .body-4;
        color: var(--emphasis-lowest);
        letter-spacing: normal;
      }
    }
  }
  // Fix for Safari
  > div {
    flex: 0 0 auto;
    display: flex;
    margin-left: 1rem;

    &.logo-wrapper {
      flex: 1;
    }
  }
}

.logo-wrapper {
  line-height: 0;
  @include breakpoint(smallmedium down) {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    img {
      max-height: 36px !important;
    }
  }
  a {
    display: inline-block;

    span {
      @extend .heading-4;
      @extend .heading-4-bold;
      @extend .heading-4-link;
      color: var(--emphasis-high) !important;
    }
  }

  img {
    display: block;
    max-height: 33px;

    @include breakpoint(medium large) {
      max-height: 45px;
    }
  }
}

/* Dropmenus in topbar */
.topbar__dropmenu {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0;
  margin-left: 0;

  .dropdown > li > a {
    background: transparent;
    padding-left: 0.25rem;
  }

  .dropdown {
    display: inline-block;
    vertical-align: middle;
    &.menu {
      z-index: 9999 !important;
    }
  }
  .dropdown.menu > li.is-dropdown-submenu-parent > a {
    transform: none;
  }
  .dropdown.menu > li.opens-right > .is-dropdown-submenu {
    top: 110%;
  }
  .is-dropdown-submenu {
    z-index: 701;
    text-align: left;
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--grey1);
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    min-width: 180px;

    &::after,
    &::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      display: none;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      color: transparent;
    }

    &::after {
      border-color: rgba(var(--emphasis-med), 0);
      border-bottom-color: $white;
      border-width: 10px;
      margin-left: -10px;
      background: transparent;
    }

    &::before {
      border-color: rgba(var(--grey1), 0);
      border-bottom-color: $white;
      border-width: 11px;
      margin-left: -11px;
    }

    li {
      padding: 0;

      a {
        @extend .body-4;
        padding: 1rem 0.25rem;
        padding-left: rem-calc(12px);
        color: var(--emphasis-med) !important;

        &:hover,
        &:focus {
          color: var(--primary) !important;
          background: var(--primary-10);
          text-decoration: none !important;
        }
      }

      &:not(:last-child) {
        border-bottom: $border;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        background-color: var(--primary-10);
        text-decoration: none;
        color: var(--primary);
      }
    }
  }

  // Arrow center position
  #topbar__language-choose .is-dropdown-submenu {
    transform: translateX(-12%);
    top: 110%;
  }

  #topbar__user-notifications .is-dropdown-submenu {
    transform: translateX(-47%);
    top: 110%;
  }

  #topbar__user-profile .is-dropdown-submenu {
    transform: translateX(-40%);
    top: 110%;
  }

  #user-menu-control {
    @extend .body-4;
    .topbar__dropmenu {
      color: var(--txt-emph-med-color);
      &:hover {
        color: var(--primary);
        background-color: var(--primary-10);
      }
      & > ul > li > a::after {
        border-top-color: var(--emphasis-med);
      }
    }
  }
}

//Foundation overwrites
.topbar__dropmenu > ul > li.is-active > a::after {
  border-top-color: var(--primary) !important;
}
.topbar__dropmenu > ul > li > a {
  color: var(--emphasis-med) !important;

  &:hover {
    text-decoration: none !important;
    color: var(--primary) !important;
    &::after {
      border-top-color: var(--primary) !important;
    }
  }

  &::after {
    margin-top: -1px !important;
    border-top-color: var(--emphasis-med) !important;
  }
}

.language-choose {
  @include breakpoint(small medium down) {
    font-size: 0.8rem;
    position: static;
    top: 0;
    left: 0;
    margin-left: 0;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
      border-width: 3px;
      right: 10px;
      margin-top: 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.topbar__user {
  display: inline-block;
  padding-right: 2.5rem;
  margin-left: 2rem;

  @media (min-width: (1300 * 0.0625) + em) {
    padding-right: 0;
  }
}

.topbar__user__logged {
  text-align: right;
  display: none;

  // hide caret down
  .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    display: none;
  }

  @include breakpoint(medium) {
    display: block;

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
      display: block;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.topbar__user__logged__picture {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: $border;
  object-fit: cover;
}

.topbar__user__logged__name {
  color: var(--primary);

  > * {
    display: block;
  }
}

.topbar__notifications,
.topbar__conversations {
  display: inline-flex;
  margin-right: 0;
  vertical-align: -3px;
  padding: 13px;
  border: 1px solid transparent;
  border-radius: 40px;
  &:hover,
  &:focus {
    background: var(--primary-10);
    .icon {
      fill: var(--primary);
    }
  }
  .icon {
    fill: var(--emphasis-med);
    opacity: 1;
  }

  &.is-active .icon {
    fill: var(--primary);
    opacity: 1;
  }
}

.topbar__notifications__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $muted;
  width: 250px;

  @include flexgap(1rem);

  strong {
    color: $anchor-color;
  }

  :last-child {
    flex: 1;

    > * {
      display: block;
    }
  }
}

.topbar__notifications__item--more {
  text-align: center;
  background-color: var(--grey1);
  color: var(--emphasis-med);
}

.topbar__user__login {
  text-align: right;
  line-height: 1.2;

  a:not(.sign-up-link) {
    @extend .body-4;
    @extend .body-4-link;
    color: var(--emphasis-med);
    &:hover,
    &:focus {
      color: var(--emphasis-high);
    }
    @include breakpoint(medium) {
      margin-left: rem-calc(32);
    }
  }
  & > a.sign-up-link {
    @extend .btn;
    @extend .btn--small;
    @extend .btn--primary;
    font-weight: normal;
    font-size: 16px;
  }
  a:first-of-type {
    @include breakpoint(medium) {
      margin-left: 0;
    }

    &::before {
      display: none;
    }
  }
  a::before {
    content: none;
    margin-left: 0;
    margin-right: 0;
    display: none;
    width: 0;
    height: 0;
    border-left: unset;
    vertical-align: middle;

    @include breakpoint(medium) {
      display: none;
    }
  }
}

/* Menu icon and off-canvas */
.topbar__menu {
  text-align: right;
  font-size: 20px;
  margin-right: 0.1rem;

  button {
    color: var(--emphasis-med);
    &:hover {
      background: var(--primary-10);
      color: var(--primary);
    }
  }
}

.topbar__admin__link {
  a {
    color: var(--emphasis-med);

    &:hover {
      background: var(--primary-10);
      color: var(--primary);
    }
  }

  ~ .topbar__admin__link {
    margin-left: rem-calc(32);
  }
}

.usermenu-off-canvas-holder .usermenu-off-canvas {
  border-top: 0;
  border-bottom: 0;

  &.is-dropdown-submenu {
    display: block;
    position: static;
    background: transparent;
    border-left: 0;
    border-right: 0;
  }

  a {
    display: block;
    padding: 1rem 3.8125rem;
    padding-left: 1.5rem;
    margin-bottom: 0.9375rem;
    color: var(--emphasis-med);

    &:hover,
    &:focus {
      background: var(--primary-10);
      color: var(--primary);
      text-decoration: none;
    }
  }
}
@include breakpoint(medium down) {
  .main-nav__link a,
  .main-nav__link--active a {
    font-weight: 500;
    &,
    &:hover,
    &:focus {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}
.main-nav > ul {
  gap: 1rem;

  & > li {
    margin-left: 0;
    @include breakpoint(large up) {
      margin-left: 0 !important;
    }
  }
}

.off-canvas {
  .main-nav {
    padding-top: 3rem;
  }
  .main-nav__link,
  .main-nav__link a {
    text-align: left;
    margin-bottom: 0;
    font-weight: 500;
  }

  .main-nav__link a {
    &,
    &:hover,
    &:focus {
      justify-content: flex-start;
      padding-left: 1rem;
      font-weight: 500;
    }
  }

  .main-nav__link--active a {
    box-shadow: none;
  }

  @media print, screen and (min-width: 40em) {
    .main-nav__link--active a {
      box-shadow: none;
    }
  }
  .input-group {
    border: 1px solid var(--grey2);
    label {
      margin-bottom: 0;
    }
  }
  .topbar__user__login {
    text-align: center;
    margin-left: rem-calc(8px);
    margin-right: rem-calc(8px);
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0 transparent;
    border-bottom: 0 transparent;
    display: flex;
    flex-direction: column;
    & > a {
      @extend .body-4-link;
      padding: 1rem 1.5rem;
      text-align: center;
    }
  }
  .search-off-canvas-holder {
    margin-left: 8px !important;
    margin-right: 8px !important;
    .topbar__search {
      max-width: 100%;
    }
  }
}

/* Main nav */
.main-nav ul {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  @include breakpoint(medium) {
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    display: flex;

    > li {
      display: inline-block;
      flex: 1;
      text-align: center;
      margin-left: rem-calc(22);
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
.main-nav__link {
  margin-bottom: rem-calc(15);
  font-weight: 500;
}
.main-nav__link a {
  @extend .btn;
  @extend .btn--secondary;
  background-color: transparent;
  color: var(--emphasis-med);
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 500;
  padding: rem-calc(16) rem-calc(1);
  width: 100%;

  &:hover {
    text-decoration: none !important;
  }
}

.main-nav__link--active a {
  &,
  &:hover,
  &:focus {
    width: 100%;
    background-color: var(--primary-10);
    color: var(--primary);
    border-radius: 4px;
    padding: rem-calc(16) rem-calc(1);

    @include breakpoint(medium) {
      box-shadow: unset;
      width: 100%;
    }
    cursor: default;
  }
}
