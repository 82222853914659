/* Variables */

$card-bg: var(--white);
$card-secondary-bg: var(--grey1);
$card-border: $border;
$card-border-radius: var(--card-corner-radius);
$card-border-top-width: 8px;
$card-shadow: var(--card-shadow);
$card-id: var(--grey2);

$card-padding-small: 1rem;
$card-padding: 1.5rem;
$card-margin-bottom: map-get($grid-column-gutter, medium);

$card-footer-bg: var(--white);

$card-proposal: var(--proposals);
$card-action: var(--actions);
$card-debate: var(--debates);
$card-meeting: var(--meetings);
$card-spaces: var(--meetings);

$datetime-bg: var(--primary);

@mixin data-item-with-label {
  @extend .body-5;
  // @extend .body-5-medium;
  color: var(--emphasis-med) !important;
  & > strong {
    letter-spacing: normal;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.03em;
    @extend .body-6;
    color: var(--emphasis-low);
  }
}

/* Cards */
.card {
  position: relative;
  margin-bottom: $card-margin-bottom;
  background: $card-bg;
  border: $card-border;
  border-radius: $card-border-radius;
  // Keep visible for accessibility (active/focused card as a link)
  overflow: visible;
  overflow-wrap: break-word;
  hyphens: auto;

  @include modifiers(
    border-top-color,
    (
      muted: rgba($muted, 0.3),
    )
  ) {
    border-top-width: $card-border-top-width;
  }
}

.card--nomargin {
  margin-bottom: 0;
}

.card--secondary {
  background: $card-secondary-bg;
  box-shadow: none;
}

.card__content {
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &:not(:only-child) {
    padding-bottom: 0;
  }

  @include breakpoint(medium) {
    padding: 0;

    &:not(:only-child) {
      padding-bottom: 0;
    }
  }
}

.card__content--center {
  text-align: center;
}

.card__extra {
  background: $card-secondary-bg;
  padding: $card-padding-small;
}

.card__title {
  @extend .body-3;
  @extend .body-3-medium;
}

a.card__title,
a .card__title {
  &:hover {
    text-decoration: underline;
  }
}

.card--assembly,
.card--proposal,
.card--collaborative_draft,
.card--debate {
  .card__text--paragraph {
    .card__text--status {
      display: inline-block;
      float: none;
    }
    & > div {
      display: inline;
    }
  }
  & > .card__content > .card__header {
    margin-top: 1.5rem;
    width: 100%;
  }
}

.card__header {
  padding-left: $card-padding;
  padding-bottom: $card-padding-small;
  padding-top: 0;
  width: 100%;
  .card__title {
    max-width: 95%;
    margin-bottom: 0.5rem;
  }

  &.collapse {
    margin-bottom: 0;
  }

  &.mb-none {
    //used on coments cards (see homepage)
    padding: 0 $card-padding $card-padding $card-padding !important;
  }
}
.btn--card__button {
  @extend .body-4;
  @extend .body-4-link;
  height: auto;
  flex: 0;
  min-width: fit-content;
  text-align: right;
  justify-content: flex-end;
  text-wrap: nowrap;
  @include breakpoint(smallmedium down) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
  }

  &,
  &:focus,
  &:hover {
    margin: 0;
    cursor: pointer;
    align-items: flex-end !important;
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    border-width: 0 !important;
  }
}
.card__button,
a.card__button {
  @extend .btn--card__button;
}

.card .card__footer a.card__button,
.card .card__footer a.button {
  @extend .btn--card__button;
}

.card.card--process {
  & > .card__footer:not(.card__footer--spaces) {
    & > .card__support {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .card__footer .card__support {
    flex-direction: row;
    align-items: center;
  }
}

.process-header .process-header__phase .button.small.button--sc {
  padding-top: 8px;
  display: inline-block;
}
.o-participatory_processes
  #highlighted-processes
  .card.card--process
  .card--process__column
  .card__content
  .button.expanded.button--sc
  span {
  min-height: 19px;
}
.process-header .process-header__phase .button.small.button--sc,
.o-participatory_processes
  #highlighted-processes
  .card.card--process
  .card--process__column
  .card__content
  .button.expanded.button--sc
  span {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: center;
}

.card__header {
  margin-bottom: 0.62rem;
}
.card__type {
  background: var(--grey1);
  padding: 0.1rem 1rem;
  margin-top: 0.5rem;
  margin-bottom: -0.75rem;
  font-size: 80%;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--grey2);
  display: flex;
  align-self: flex-start;
  align-items: center;

  .icon {
    width: 14px;
    height: 14px;
    fill: $muted;
    margin-right: 0.2rem;
  }
}
.card .tags {
  padding: $card-padding-small $card-padding;
}
.card__text {
  //Changed from padding-top = 0 to card-padding-small in order to fix wrongly wrapped texts in cards
  padding: $card-padding-small $card-padding;
  margin-bottom: 0;
  &:first-child {
    margin-top: -0.25em; //balances top alignment in card
  }
  p {
    margin-bottom: 0;
  }
  a:hover {
    color: var(--secondary);
    text-decoration: underline;
  }

  .card__text--status {
    @extend .body-4;
    @extend .body-4-bold;
    @extend .body-4-caps;
    float: left;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.2;
    margin-right: 0.25rem;
    &::after {
      content: " ";
    }

    @include modifiers(
      color,
      (
        muted: $muted,
      )
    );
  }

  &:not(:last-child) {
    margin-bottom: $global-margin * 0.5;
  }

  &.card--picture-offset {
    // _author-avatar.scss:58 (.author-data--big .author__avatar img)
    // _author-avatar.scss:133 padding (.author__avatar)
    margin-left: calc(#{rem-calc(32)} + 0.5rem);
  }
}

.card__text--category,
.tags {
  padding: $card-padding-small $card-padding;
  display: block;
  margin-top: 0;
}

.card__text--separated-mid-dot {
  @include inline-separator(" · ");
}

a.js-link-with-image,
a.js-link-image {
  position: relative;
  padding-bottom: 42.6315%;
  overflow: hidden;
}

a:has(.card__image img, .card__image-top
    img, img.card__image, img.card__image-top) {
  position: relative;
  padding-bottom: 42.6315%;
  overflow: hidden;
}

.card:not(.alert) a:has(.card__image-top),
.card:not(.alert) a:has(img.card__image),
.card:not(.alert) a.js-link-with-image {
  border-top-left-radius: var(--card-corner-radius);
  border-top-right-radius: var(--card-corner-radius);
}

.card.card--meeting,
.card.card--proposal,
.card.card--collaborative_draft {
  &:not(.alert) a:has(.card__image-top),
  &:not(.alert) a:has(img.card__image),
  &:not(.alert) a.js-link-with-image {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.card__image {
  background: var(--grey1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover !important;
  background-position: center !important;

  &.card__image--smaller,
  &.card__image--larger {
    max-height: auto;
    min-height: auto;
  }
}

/* Card datetime */
// NOTE: Possible deprecated
.card__datetime {
  flex-shrink: 0;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-weight: var(--font-weight-semibold);
  display: flex;
  align-items: baseline;
  border-left: 4px solid var(--primary);
  background: $card-secondary-bg;
  line-height: 1;
}

.card__datetime__date {
  font-size: 1.4rem;
  margin-right: 1rem;
}

.card__datetime__month {
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1.2rem;
}

.card__datetime__time {
  font-size: 0.8rem;
}

/* Card footer */
.card__footer {
  background: $card-footer-bg;
  border-top: $card-border;
  border-radius: 0 0 $card-border-radius $card-border-radius;
  clear: both;

  .card__content {
    display: flex;
  }
  & > .card__support > .card__button,
  .card__button,
  .card__button.button.button--sc.secondary.light.small {
    @extend .btn--card__button;
  }
}

.card__footer--transparent {
  background: transparent;
}

.card__footer--data {
  display: flex;
  align-items: center;

  .card__content {
    flex-grow: 0;
  }

  .card-data {
    flex-grow: 1;
    flex-direction: column;
  }

  .card-data__item {
    flex-basis: auto;
    border-right: none;
    border-left: $border;
    border-bottom: $border;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.card.card--activity .card__author.inline-block {
  padding-top: 0;
}

.card__author {
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  line-height: 1.8;
  //Used in blog component
  padding: 0.6rem 0;

  // Hack when there's a toggler list
  [data-toggler] {
    margin-bottom: -0.5rem;
  }

  &.inline-block {
    display: inline-block;
    padding: 0;
  }
}

.card__voting {
  display: inline-block;
  text-align: center;
}

.card__vote-number {
  font-size: 1.3rem;
  font-weight: var(--font-weight-semibold);
  line-height: 1;
}
.card.card--activity {
  padding-top: 1rem;
  .card__content {
    margin: 0;
  }
  .card__text {
    top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    position: relative;
  }
  .card__text .text-medium {
    @extend .body-5;
  }
}
/* Card activity date */
.card__activity-date {
  margin-bottom: 0rem;
  .text-medium {
    @extend .body-5;
  }
  &.inline-block {
    display: inline-block;
    padding-left: 1.5rem;
    &.before-content::before {
      content: "";
      margin-right: 0.8rem;
      border-right: $border;
      height: 0;
      display: none;
      vertical-align: middle;
    }
  }
}

/* Card data */
.card-data {
  list-style: none;
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  //removed after padding modifications
  //border-top: var(--divider-border);

  &:empty,
  &:blank {
    border-top: 0;
  }

  a:hover {
    text-decoration: underline;
  }
}

.card-data__item--centerblock {
  @extend .card__data-item;
  margin: 0 auto;
  text-align: center;

  & > .text-left {
    padding: $card-padding;
    text-align: left;
  }
}

.card-data__item {
  @extend .card__data-item;
  flex-basis: 33.33%;
  flex-grow: 1;
  text-align: inherit;
  color: $muted;
  padding: 1rem 1px 1rem $card-padding;
  border-right: $border;
  margin-right: -1px;
  border-top: $border;
  margin-top: -1px;
  font-weight: normal;
  strong {
    width: 100%;
    display: inline-block;
    font-weight: normal !important;
  }

  &.card-data__item--expand {
    line-height: $global-lineheight;
  }

  [data-toggle] {
    margin-bottom: 0;
  }

  > div {
    @include data-item-with-label;
  }
}

.card-data__item__number {
  display: block;
  line-height: 1;
  font-weight: var(--font-weight-semibold);
  font-size: rem-calc(20);
  color: var(--emphasis-med);
  margin-bottom: 0.2rem;
}

.card-data__item--multiple {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include flexgap(1rem);

  > div {
    text-align: left;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
  }
}

/* Card popularity */

.card__popularity {
  padding: $card-padding;
  text-align: center;

  .icon {
    fill: var(--grey2);
  }
}

.card__popularity__data {
  text-align: center;
  font-size: 85%;
  line-height: 1;
  margin-bottom: 0.2rem;
  color: $muted;
}

/* Card support */

.card__support {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: var(--divider-border);
  padding: 1rem;
  margin-left: auto;
  justify-content: flex-end;
  &:has(div) {
    justify-content: space-between;
  }
  & > :last-child {
    justify-self: flex-end;
  }
  &:empty {
    display: none;
  }

  & > *:only-child {
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @include breakpoint(medium) {
    padding: 1rem;
  }

  & > .card__button:first-child {
    margin-left: auto;
  }

  .card__button {
    @extend .btn--card__button;
    text-wrap: pretty !important;
    text-align: left !important;
  }

  > .author-data {
    margin-bottom: 0;
  }
}

.card__support__data {
  text-align: center;
  font-size: 85%;
  line-height: 1;
  margin-bottom: 0.2rem;
  color: $muted;
}

.card__support__data--left {
  @extend .card__support__data;
  margin-right: auto;
  text-align: left;
  text-transform: none;
  margin-bottom: 0;
  color: $muted;
  max-width: 60%;

  strong {
    font-size: rem-calc(16);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.card__support__number {
  line-height: 1;
  font-weight: var(--font-weight-semibold);
  font-size: rem-calc(20);
  color: var(--primary);
}

/* Card with image */
.card__img {
  text-align: center;

  @include breakpoint(large) {
    float: right;
    width: 35%;
  }
}

.card__content--img {
  @include breakpoint(large) {
    float: left;
    width: 60%;
  }
}

/* Card Link */
.card__link {
  color: var(--primary);
  &:hover {
    color: var(--primary);
    text-decoration: none;
    > :first-child {
      text-decoration: underline;
    }
  }
}

.card__link--block {
  display: block;
}

/* Card list */
.card--list {
  .card--list__item:not(:last-child),
  *:not(:last-child) .card--list__item {
    border-bottom: 1px solid var(--grey2);
  }
}

.card--list__item {
  display: flex;
  align-items: center;
  padding-left: $card-padding-small;

  .card--list--mini & {
    padding-left: $card-padding-small;

    .card__link {
      display: flex;
      align-items: center;
    }
  }

  @include breakpoint(medium) {
    padding-left: $card-padding;
  }

  svg {
    flex-basis: 15%;
    flex-shrink: 0;
  }

  svg + div {
    max-width: calc(85% - #{$global-margin});
    overflow-wrap: break-word;
  }
}

.card--list__icon {
  display: none;
  fill: $anchor-color;

  @include modifiers(fill);

  @include breakpoint(medium) {
    margin-right: 1rem;
    display: block;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
}

.card--list__text {
  flex-grow: 1;
  flex-shrink: 1;
  display: inline-flex;
  align-items: center;
  padding: 1rem 1rem 1rem 0;
  // Keep visible for accessibility (active/focused card as a link)
  overflow: visible;

  &.card--list__text--top {
    align-items: start;
  }

  .card--list--mini & {
    padding: 0.5rem 0.5rem 0.5rem 0;

    > * {
      flex-grow: 1;
    }
  }

  .author {
    margin-top: 0.3rem;
    margin-right: 1rem;
  }
}

.card--list__heading {
  margin-bottom: 0;
  display: block;

  &.heading6 {
    text-transform: unset;
    letter-spacing: unset;
    font-weight: unset;
  }
}

.card--list .tags {
  font-size: initial;
  > li > a {
    @extend .chip;
    padding-left: 0 !important;
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.card--list__data {
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 85%;
  min-width: 7rem;
  line-height: 1;
  color: var(--emphasis-med);
  padding: $card-padding-small;
  background: var(--white);

  @include breakpoint(medium) {
    padding: 1rem $card-padding;
  }

  .card--list--mini & {
    padding: 0.5rem $card-padding-small;
  }
}

.card--list__data-added {
  background-color: tint($success, 80%);

  a {
    color: var(--primary);
  }
}

.card--list__check {
  margin-bottom: 0;
  border-radius: 50%;
  align-self: center;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  &--disabled {
    pointer-events: none;
  }

  &--inline {
    display: inline-block;
  }

  svg {
    flex-basis: auto;
  }
}

.card--list__data__icon {
  .icon {
    width: 20px;
    height: 20px;
    fill: $muted;
  }

  &:hover {
    .icon {
      fill: $anchor-color;
    }
  }
}

.card--list__data__icon--lg {
  @extend .card--list__data__icon;

  .icon {
    width: 36px;
    height: 36px;
  }
}

.card--list__data__number {
  @extend .card-data__item__number;

  font-size: 1.5rem;
}

.card--list__titled-text {
  @extend .card--list__text;

  flex-wrap: wrap;

  $date-width: 28px;

  .card--list__heading {
    margin-bottom: 5px;
    width: calc(100% - #{$date-width});
  }

  .card--list__annotation {
    letter-spacing: 0.03em;
    font-size: 0.7em;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    width: $date-width;
  }

  .card--list__counter {
    $counter-size: 20px;
    $counter-font-size: $counter-size - 8px;

    background-color: var(--secondary);
    border-radius: 50%;
    color: var(--white);
    font-size: $counter-font-size;
    font-weight: var(--font-weight-semibold);
    margin-left: auto;
    width: $counter-size;

    span {
      display: block;
      line-height: $counter-size;
      text-align: center;
    }
  }
}

.card--list__author {
  @extend .card--list__titled-text;

  padding: 0.5rem 1rem 0.5rem 0;
  margin-left: -0.5rem;

  .author {
    margin: 0;
  }

  .author__avatar img {
    border-radius: 2px;
  }
}

/* Card full width variant */
.card--full {
  margin-bottom: $card-margin-bottom * 2;

  &:last-child {
    margin-bottom: 0;
  }

  .card__title {
    @extend .heading3;

    &:hover {
      text-decoration: underline;
    }
  }

  .card__content {
    padding: $card-padding;
    padding-bottom: 0;
    @include breakpoint(large) {
      padding: $card-padding * 2;
      padding-bottom: 0;
    }
  }
}

.card--full__image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 0 $card-border-radius $card-border-radius 0;
  background-color: var(--grey1);

  @include breakpoint(medium only) {
    min-height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button.expanded {
    @extend .btn;
    @extend .btn--medium;
    width: 100%;
    text-wrap: nowrap;
  }
  > .card__content {
    position: relative;
    z-index: 1;

    @include breakpoint(medium only) {
      min-width: 50%;
    }

    @include breakpoint(medium large) {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

//small card with top image
.card__image-top {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--grey2);

  height: 10rem;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  overflow: hidden;
  .card--process & {
    display: none;

    @include breakpoint(medium large) {
      display: block;
    }
  }
}

/* Card full width process variant */
.card--process__row {
  flex-direction: row;
  .card--process__column:last-child {
    display: flex;
    visibility: visible;
  }
  @include breakpoint(small only) {
    display: flex;
    flex-direction: column;
    .card--process__column:last-child {
      display: none;
      visibility: hidden;
    }
  }
}

@include breakpoint(medium large) {
  .card--process__row {
    display: flex;
  }

  .card--process__column {
    position: relative;

    .card--full__image-wrapper {
      height: 100%;
      overflow: hidden;
    }

    .card--full__image {
      height: 100%;
      position: absolute;
      right: -1px;
      left: 0;

      > img {
        min-height: 100%;
        max-width: initial;
        position: absolute;
      }
    }
  }
}

.card--process__small {
  font-size: $small-font-size;

  &:last-child {
    margin-bottom: 0;
  }
}

// card mini
.card--mini {
  .card__content {
    padding: $card-padding $card-padding-small;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .card__image-top {
    display: block;
    height: 8rem;
    background: var(--grey2);
  }

  .card--process__small {
    margin-top: auto;
  }
}

// card status
.card__status {
  //fix after card-data modifications
  padding: 0;
  margin: 0;
  border-top: var(--divider-border);

  text-align: center;
  &:not(:has(li)) {
    display: none;
  }
  .card-data__item {
    text-align: center;
    flex-basis: 20%;
    flex-basis: initial;
    flex: 1;
    //was used by ?
    //padding: $card-padding;
    //used by proposal card
    padding-left: 0;

    //used after padding modifications
    padding-top: $card-padding-small;
    padding-bottom: $card-padding-small;

    //-------F
    //Used in participitory process cards created at item
    @include data-item-with-label;
    //-------

    &:empty {
      border-right: 0;
      display: none;
      & + .card-data__item:not(:empty) {
        border-left: $border;
      }
    }
    &.creation_date_status,
    & > div.card-data__item--centerblock {
      @include data-item-with-label;
      display: inline-flex;
      align-items: center;
      > strong {
        display: inline-block;
        margin-bottom: 0.25rem;
      }
      &:nth-child(1):nth-last-child(1) {
        text-align: left;
        align-items: flex-start !important;
      }
    }

    &.follow_status {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 0;
      text-align: center;
      display: inline-flex;
      align-items: center;
      .text-wrap {
        text-transform: none !important;
      }
      &.card--meeting {
        text-align: left;
      }
    }

    &.comments_count_status,
    &.endorsements_count_status {
      padding: $card-padding $card-padding-small !important;
      padding-top: calc($card-padding) !important;
      flex: 0.25;
    }
  }
}

// card label
.card__label {
  @extend .body-5;
  @extend .body-5-bold;
  color: $muted;
  text-shadow: none;
  line-height: 50%;

  position: absolute;
  display: inline-block;
  background-color: $body-background;
  height: 8px;
  margin-top: 0 !important;
  padding: 0 6px;

  left: 18px;
  top: -8px;
  z-index: 1;

  &::before {
    display: none;
    height: 0;
  }

  .icon {
    // Reduces some icon space
    margin-left: -0.25rem;
  }

  @include modifiers(
    color,
    (
      muted: tint($muted, 50),
    )
  );

  @include breakpoint(medium) {
    margin-left: 0;
  }
}

// card metadata with icon
.card__icondata {
  //was used by ?
  //padding: $card-padding;
  //used by participatory process card
  padding: 0;
  padding-right: 0.5em;
  //remove after padding modifications
  &:has(li) {
    border-top: var(--divider-border);
  }
  ul {
    //Used by meetings card to add padding to adress and location
    padding: 0 $card-padding;

    align-items: stretch;
    border-top: 0;

    li {
      border-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        margin: 0.5rem;
      }
    }

    li:first-child {
      display: none;
    }

    li:not(:first-child) {
      @extend .card__data-item;
      padding-left: 0 !important;
      > div:not(.card-data__item--centerblock) strong:not(.heading4) {
        display: inline-block;
        width: auto;
        margin: 0;
        color: var(--emphasis-low);
      }
      > div {
        @include data-item-with-label;
        margin: 0 !important;
      }

      .card__icondata--address {
        color: var(--primary);
        line-height: 120%;

        & + a {
          display: block;
          margin-top: $global-margin;
        }
      }
    }
  }
}
.card__block {
  align-self: flex-end;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex: 1;
  border-top: 0;
  .card__icondata {
    &:has(li) {
      border-top: 0;
    }
  }
}
.card__block {
  @extend .card__icondata;

  margin-bottom: 0;
  padding-top: 0.5rem;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;

  ul {
    border: $border;
    //removed after padding modifications
    //border-top: var(--divider-border);

    li {
      padding: 0.25em;
    }
  }

  > .text-small {
    @extend .body-6;
    @extend .body-6-caps;
    color: var(--emphasis-lowest);
    padding: 0 $card-padding;
  }
}
.o-profiles--following .card__label.card--assembly.card--stack {
  align-self: flex-start;
  z-index: 10;
}
.o-profiles--following .card-grid .card {
  border-top: 8px solid var(--grey2);
  .card__label {
    color: var(--emphasis-lowest);
  }
  &.muted {
    border-top-color: var(--emphasis-lowest);
    .card__label {
      color: var(--emphasis-lowest);
    }
  }
  &.sucess {
    border-top-color: var(--green);
    .card__label {
      color: var(--green);
    }
  }
  &.warning {
    border-top-color: var(--yellow);
    .card__label {
      color: var(--yellow);
    }
  }
  &.alert {
    border-top-color: var(--red);
    .card__label {
      color: var(--red);
    }
  }

  & > a {
    border-radius: 0 !important;
  }
}

// card stack
.card--stack {
  overflow: visible;
  z-index: 0;

  &,
  &::after,
  &::before {
    /* Add shadow to distinguish sheets from one another */
    box-shadow: none;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: $card-border-radius;
  }

  /* Second sheet of paper */
  &::before {
    left: 3px;
    top: 3px;
    z-index: -1;
  }

  /* Third sheet of paper */
  &::after {
    left: 5px;
    top: 5px;
    z-index: -2;
  }
}

.card--widget {
  .card-data {
    flex-wrap: inherit;

    .card-data__item {
      @extend .body-5;
      @extend .body-5-link;
      color: var(--primary);
      text-transform: none;
      text-align: left;
      padding: 0;
      padding-top: $card-padding-small;
      padding-bottom: $card-padding-small;

      @include modifiers(color);

      @include breakpoint(medium) {
        padding: $card-padding;
      }

      &:first-child {
        flex-grow: 0;
        flex-basis: 12%;
        background-color: $card-secondary-bg;
        position: relative;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include absolute-center;
        }
      }
    }
  }
}

// card - participatory space
.card__top {
  @extend .card__footer;

  border-top: 0;

  .card__content {
    line-height: $global-lineheight;
    padding: $card-padding-small * 0.25 $card-padding-small;
    margin-bottom: 0;
    flex-direction: row;
    > :first-child {
      flex-shrink: 0;
    }

    @include breakpoint(medium) {
      padding: $card-padding * 0.25 $card-padding;
    }
  }
}

// card row
.card__row {
  @include flex;
  @include flex-align($x: left, $y: null);

  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }

  > * {
    padding: $global-margin;
    flex: 0 0 25%;

    &:not(:nth-child(4n)) {
      border-right: 0;
      border-bottom: $card-border;

      @include breakpoint(medium) {
        border-bottom: 0;
        border-right: $card-border;
      }
    }
  }
}

.card--post {
  > .card__content {
    > .card__header {
      > .card__link {
        > .card__title {
          @extend .body-2;
          @extend .body-2-medium;
        }
      }
    }
  }
}

.endorsements_count_status,
.comments_count_status {
  padding: $card-padding $card-padding-small !important;
  padding-top: calc($card-padding) !important;
}
