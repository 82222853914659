.loading-spinner {
  margin: 25px !important;
  height: 25px;
  text-align: center;

  &::before {
    @include spinner(25px, $medium-gray, var(--primary), 800ms);

    content: "";
    vertical-align: middle;
  }
}

.spinner-container {
  position: relative;
  cursor: wait;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($grey1-color, 0.8);
    z-index: 11;
  }

  &::after {
    -webkit-animation: animation-spin 800ms infinite linear;
    animation: animation-spin 800ms infinite linear;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 5px solid $grey1-color;
    border-right-color: var(--primary);
    display: inline-block;
    position: absolute;
    content: "";
    vertical-align: middle;
    z-index: 15;
    top: 64px;
    left: 47%;
  }
}
