$autocomplete-item-color: var(--primary);
$autocomplete-item-hover-bg: var(--primary-10);
$autocomplete-multiselect-bg: $grey1-color;
$autocomplete-multiselect-border-color: $grey2-color;
$autocomplete-multiselect-search-prompt-color: var(--emphasis-lowest);

.autoComplete_wrapper {
  width: 100%;
  max-width: 100%;

  input {
    color: $black;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  ul {
    max-width: 100%;
    position: absolute;
    margin: 0;

    li {
      color: $autocomplete-item-color;

      span {
        &.author__avatar {
          display: inline-block;
        }
      }

      &:hover,
      &[aria-selected="true"] {
        background-color: $autocomplete-item-hover-bg;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      .is-group {
        float: right;
      }
    }
  }

  span {
    &.autocomplete__selected-item.sticky {
      position: absolute;
      height: 3rem;
      line-height: 3rem;
      padding: 0 1rem;
    }
  }

  .clear-sticky-selection {
    display: inline;
    position: absolute;
    font-size: 1.3rem;
    cursor: pointer;
    right: 1rem;
  }

  .clear-multi-selection {
    cursor: pointer;
  }

  .search-prompt {
    color: $autocomplete-multiselect-search-prompt-color;
    border: 1px solid $autocomplete-multiselect-border-color;
    padding: 0.2rem;
  }
}

.multiselect {
  background-color: $autocomplete-multiselect-bg;
  display: inline-block;
  border: 1px solid $autocomplete-multiselect-border-color;
  width: 100%;
  cursor: text;
  padding: 0 0.5rem;
  min-height: 2.4375rem;

  span {
    display: inline-block;
    position: relative;
    margin: 0.5rem 0.5rem 0.5rem 0;

    input {
      border: none;
      outline: none;
    }

    &.clear-multi-selection {
      margin: 0 0 0 0.2rem;
      cursor: pointer;
    }
  }
}
