.card.card--process {
  .card--process__small {
    color: var(--emphasis-med);
    text-decoration: none !important;
    margin-top: 0.3rem;
  }
  &:hover,
  &:focus {
    text-decoration: none !important;
    .card__title.card__link {
      text-decoration: underline;
    }
    .card--process__small {
      color: var(--emphasis-med);
      text-decoration: none !important;
    }
  }
  .card__content {
    padding-bottom: 2rem;
  }
  .card__content > .ql-editor-display {
    margin-bottom: 1rem;
  }
  .card__content > .ql-editor.ql-reset-decidim {
    padding: 0 !important;
    display: block;
    margin-bottom: 1rem;
  }
  .card__header {
    margin-top: 1.5rem;
    width: 100%;
  }
}
