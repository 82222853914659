.o-searches {
  @include filters-area;
  .input-group label {
    margin-bottom: 0;
  }
  .section-heading:not(.first-child) {
    margin-top: 2rem;
    @include breakpoint(large) {
      margin-top: 0;
    }
  }
  .card.card--meeting,
  .card.card--budget,
  .card.card--project,
  .card.card--post,
  .card.card--comment,
  .card.card--debate {
    border-top: 6px solid var(--grey2);
  }
}
