.home-section:nth-of-type(2n + 1) {
  background-color: white;
}

.hero {
  height: 90vh;
  min-height: 70vh;
  max-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(small only) {
    height: 70vh;
  }
}

.hero-heading {
  text-align: left;
  margin-bottom: 0;
}
.home-section.upcoming-meetings .icon--datetime {
  display: none;
}
.home-section .highligted-content-banner__container {
  .button.button--sc {
    flex-direction: column;
  }
}
